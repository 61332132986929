<template>
  <div class="policy-page">
    <v-row justify="center" align="center">
      <v-col>
        <div class="text-center">
          <vuetify-logo />
        </div>

        <h2>سياسة الإسترجاع</h2>
        <div class="card">
          <p v-html="policy"></p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ServiceFactory } from "../services/ServiceFactory";
const aboutService = ServiceFactory.get("about");

import VuetifyLogo from "../components/VuetifyLogo.vue";
export default {
  data: () => ({
    policy: null,
    // policy:
    //   "نشكر المنتج بهيئته المباع بها.",
  }),
  components: {
    VuetifyLogo,
  },
  created() {
    this.fetchAllItems();
  },
  methods: {
    async fetchAllItems() {
      this.dataLoading = true;
      const data = await aboutService.getPolicyData();
      this.policy = data.refund_policy;
      this.dataLoading = false;
    },
  },
};
</script>

<style>
.policy-page {
  text-align: center;
}
.policy-page h2 {
  margin: 50px 0 30px 0;
  text-align: center;
  color: #614c37 !important;
  font-size: 15px !important;
}
.policy-page .card {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  background: #fff;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 30px;
}
.policy-page .card p {
  font-size: 15px !important;
  color: #5a5a5a;
  font-weight: normal;
  /* font-family: "Roboto", sans-serif !important; */
}
</style>
